import util from './utils';

export async function sessionLogout() {
    try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", util.getAuthorizationToken());
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
        };

        const response = await fetch(
            `${process.env.URL_API_MONITOR}/login/session/logout`,
            requestOptions,
        );

        localStorage.clear();
        sessionStorage.clear();
        window.location.href = `${process.env.VUE_APP_MONITOR_URL}/login`;

    } catch (error) {
        console.error(error.message);
    }
}
