const util = {
    getAuthorizationToken: () => {
        const ipLocal = this.getIpLocal();
        const ipPublic = this.getIpPublic();
        const token = this.getToken();
        return `${token}|${ipPublic}|${ipLocal}`;
    },
    getToken: () => {
        return sessionStorage.getItem("token");
    },
    setToken: (token) => {
        sessionStorage.setItem("token", token);
    },
    getIpLocal: () => {
        return sessionStorage.getItem("ip_local");
    },
    getIpPublic: () => {
        return sessionStorage.getItem("ip_public");
    },
    getRefreshToken: () => {
        return sessionStorage.getItem("refresh");
    },
    decodeJWTPayload(token) {
        try {
            const [header, payload, signature] = token.split('.');

            const base64 = payload
                .replace(/-/g, '+')
                .replace(/_/g, '/');

            const padded = base64.padEnd(base64.length + (4 - (base64.length % 4)) % 4, '=');

            const decoded = decodeURIComponent(
                atob(padded)
                    .split('')
                    .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                    .join(''),
            );

            return JSON.parse(decoded);
        } catch (error) {
            console.error('Error decodificando JWT:', error);
            return null;
        }
    },
};

export default util;
