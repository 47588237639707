<template>
	<div class="authorization" style="overflow-y: hidden !important">
		<Loader />
	</div>
</template>

<script>
import Loader from "./components/loader";
import ip from "ip";
import { sessionLogout } from '@/utils/auth';
const publicIp = require("what-is-my-ip-address");

export default {
	components: {
		Loader,
	},
	data() {
		return {
			listenApiUrl: process.env.VUE_APP_LISTEN_API_URL,
			// "https://api.listening.isocialcube.com/graphql" "https://4f77clakv4.execute-api.us-east-1.amazonaws.com/dev/graphql" */ /* "http://localhost:3000/graphql" */,
		};
	},
	methods: {
		getToken() {
			return this.$route.query.token;
		},
		getRefresh() {
			return this.$route.query.refresh;
		},
		getIp() {
			return ip.address();
		},
		async getIpPublic() {
			return await publicIp.v4();
		},
		async queryPetition(endpoint, query, variables) {
			const response = await fetch(endpoint, {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					api_key: "a6e71e02cd9c6565c2ad2fbaf135bf64",
				},
				mode: "cors",
				cache: "no-cache",
				body: JSON.stringify({
					operationName: "validateJWT",
					query: query,
					variables: variables,
				}),
			});
			return response.json();
		},
		async validateToken(token, publicIp, ip) {
			const query = `query validateJWT($token: String, $ip_public: String, $ip_local: String) {
        validateJWT(token: $token, ip_public: $ip_public, ip_local: $ip_local) {
          code
          message
          messageError
          decode
          dateExpiredToken
          newToken
        }
      }
      `;
			const variables = {
				token: token,
				ip_public: publicIp,
				ip_local: ip,
			};

			return this.queryPetition(this.listenApiUrl, query, variables);
		},
	},
	async created() {
		try {
			const asyncSessionStorage = {
				setItem: async function (key, value) {
					return Promise.resolve().then(function () {
						sessionStorage.setItem(key, value);
					});
				},
			};
			sessionStorage.clear();
			const tokenURL = this.getToken();
			const refreshURL = this.getRefresh();
			const ip = this.getIp();
			const publicIp = await this.getIpPublic();
			if (tokenURL && refreshURL) {
				const {
					data: { validateJWT },
				} = await this.validateToken(tokenURL, publicIp, ip);
				if (validateJWT.code != 0) throw new Error("Error validating tokens!");
				switch (validateJWT.code) {
					case 0:
						if (!validateJWT.decode.username) throw new Error("Error validating tokens!");
						const vueObject = this;
						asyncSessionStorage.setItem("token", tokenURL).then(function () {
							vueObject.$store.dispatch("global/setToken", tokenURL);
							asyncSessionStorage.setItem("refresh", refreshURL).then(function () {
								window.dispatchEvent(
									new CustomEvent("tokens-changed", {
										detail: {
											token: sessionStorage.getItem("token"),
											refresh: sessionStorage.getItem("refresh"),
										},
									})
								);
								vueObject.$store.dispatch("global/setRefresh", refreshURL);
								asyncSessionStorage
									.setItem("client_id", validateJWT.decode.id_cliente)
									.then(function () {
										asyncSessionStorage
											.setItem("username", validateJWT.decode.username)
											.then(function () {
												asyncSessionStorage
													.setItem("name", validateJWT.decode.nombres)
													.then(function () {
														asyncSessionStorage
															.setItem("ip_public", publicIp)
															.then(function () {
																asyncSessionStorage
																	.setItem("ip_local", ip)
																	.then(function () {
																		asyncSessionStorage
																			.setItem("module", "advise")
																			.then(function () {
																				window.dispatchEvent(
																					new CustomEvent("user-changed", {
																						detail: {
																							name: sessionStorage.getItem(
																								"name"
																							),
																							username:
																								sessionStorage.getItem(
																									"username"
																								),
																							module: sessionStorage.getItem(
																								"module"
																							),
																						},
																					})
																				);
																				vueObject.$store.dispatch(
																					"global/setName",
																					validateJWT.decode.nombres
																				);
                                        if (validateJWT.decode["cognito:groups"].length) {
																				const options = {
																					method: "POST",
																					headers: {
																						"Content-Type":
																							"application/json",
																					},
																					body: JSON.stringify({
																						operationName: "isAdmin",
																						query: `
                                                query isAdmin($cognitoGroup: String!) {
                                                  isAdmin(cognitoGroup: $cognitoGroup)
                                                }
                                              `,
																						variables: {
																							cognitoGroup:
																								validateJWT.decode["cognito:groups"][0],
																						},
																					}),
																				};
																				fetch(
																					`${process.env.VUE_APP_BACKOFFICE_API_URL}`,
																					options
																				)
																					.then((response) => response.json())
																					.then((data) => {
																						sessionStorage.setItem(
																							"is_admin",
																							data?.data?.isAdmin ?? false
																						);
																						location.reload();
																					});
                                        }else {
                                          sessionStorage.setItem("is_admin", false);
                                          location.reload();
                                        }
																			});
																	});
															});
													});
											});
									});
							});
						});

						this.$router.push({ name: "/do-it-yourself" });
						break;
          case 1002:
            await sessionLogout();
            console.error("Expired Token!");
            break;
					default:
            await sessionLogout();
						throw new Error("Invalid Token!");
				}
			} else {
        await sessionLogout();
				throw new Error("Missing query string!");
			}
		} catch (error) {
			console.error(error);
			sessionStorage.clear();
			window.location.href = `${process.env.VUE_APP_MONITOR_URL}/login`;
		}
	},
	mounted() {},
};
</script>

<style scoped></style>
